<template>
  <div>
    <!-- 标题 -->
    <div class="page-title">
      用户管理
      <el-button plain type="success" size="mini" style="margin-left:2rem" @click="showAddLayer">+ 新建用户</el-button>
      <el-button plain type="primary" size="mini" style="margin-left:2rem" @click="editPwdLayer=true">修改我的密码
      </el-button>
    </div>
    <!-- 表格 -->
    <div style="float:left;width:100%;margin-top:1rem">
      <el-table
        border
        :data="userLists"
      >
        <el-table-column prop="用户名" label="用户名" width="120"></el-table-column>
        <el-table-column label="启用状态" width="100" :formatter="statusFormat"></el-table-column>
        <el-table-column label="账户权限" :formatter="canFormat"></el-table-column>
        <el-table-column label="操作" width="340">
          <template slot-scope="scope">
            <el-button
              type="warning"
              size="mini"
              plain
              @click="showEditLayer(scope.$index, scope.row)"
            >修改用户
            </el-button>
            <el-button
              size="mini"
              plain
              type="success"
              @click="showLogLayer(scope.$index, scope.row)"
            >登录历史
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="showDelLayer(scope.$index, scope.row)">删除用户
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 新建 用户层 -->
    <el-dialog
      fullscreen
      title="新建用户"
      :visible.sync="addLayer"
      :close-on-click-modal="true"
      :lock-scroll="true"
      :append-to-body="true"
    >
      <!-- 新建表单 -->
      <el-form>
        <el-form-item label="用户名" label-width="120px">
          <el-input
            clearable
            placeholder="请输入用户名，支持汉字"
            style="width:220px"
            v-model="addForm.用户名"
          ></el-input>
        </el-form-item>
        <el-form-item label="密 码" label-width="120px">
          <el-input
            clearable
            type="password"
            placeholder="请输入用密码"
            style="width:220px"
            v-model="addForm.密码"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="120px">
          <el-switch
            v-model="addForm.启用状态"
            active-text="启用"
            inactive-text="禁用">
          </el-switch>
        </el-form-item>
      </el-form>
      <!-- 底部按钮 -->
      <div style="margin-top:30px;padding-left:120px">
        <el-button type="primary" @click="doAddUser" plain>创建用户</el-button>
        <el-button @click="addLayer =false" type="danger" plain>取 消</el-button>
      </div>
    </el-dialog>
    <!-- 修改 用户层 -->
    <el-dialog
      fullscreen
      title="修改用户权限"
      :visible.sync="editLayer"
      :close-on-click-modal="true"
      :lock-scroll="true"
      :append-to-body="true"
    >
      <!-- 修改表单 -->
      <el-form>
        <el-form-item label="用户名：" label-width="120px">{{ editForm.用户名 }}</el-form-item>
        <el-form-item label-width="120px">
          <el-switch
            v-model="editForm.启用状态"
            active-text="启用"
            inactive-text="禁用">
          </el-switch>
        </el-form-item>
        <el-form-item label="权限设置：" label-width="120px">
          <div v-for="权限组 in 权限配置" style="width: 100%;float:left">
            <el-checkbox
              v-for="权限 in 权限组"
              v-model="editForm.权限"
              :label="权限"
              :value="权限"
            ></el-checkbox>
          </div>
        </el-form-item>
        <el-form-item label="新密码：" label-width="120px">
          <el-input
            clearable
            type="password"
            placeholder="留空则不修改"
            style="width:220px"
            v-model="editForm.密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部按钮 -->
      <div style="margin-top:30px;padding-left:120px">
        <el-button type="primary" @click="doEditUser" plain>修改用户</el-button>
        <el-button @click="editLayer = false" type="danger" plain>取 消</el-button>
      </div>
    </el-dialog>
    <!-- 登录历史层 -->
    <el-dialog
      width="520px"
      title="登录历史"
      :visible.sync="historyLayer"
      :lock-scroll="true"
      :append-to-body="true"
    >
      <el-timeline reverse="reverse">
        <el-timeline-item
          v-for="item in loginHistory"
          :timestamp="item.登录时间"
        >登录IP {{ item.登录IP }}
        </el-timeline-item>
      </el-timeline>
    </el-dialog>
    <!-- 修改自己的密码层 -->
    <el-dialog
      width="520px"
      title="修改我的密码"
      :visible.sync="editPwdLayer"
      :close-on-click-modal="false"
      :lock-scroll="true"
      :append-to-body="true"
    >
      <el-form>
        <el-form-item label="旧密码：" label-width="120px">
          <el-input type="password" placeholder="请输入新密码" v-model="editPwdForm.旧密码"></el-input>
        </el-form-item>
        <el-form-item label="新密码：" label-width="120px">
          <el-input type="password" placeholder="请输入新密码" v-model="editPwdForm.新密码"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码：" label-width="120px">
          <el-input type="password" placeholder="请再次输入新密码" v-model="editPwdForm.确认新密码"></el-input>
        </el-form-item>
        <el-form-item label-width="120px">
          <el-button type="success" @click="doEditPwd">确认修改</el-button>
          <el-button type="primary" plain @click="editPwdLayer=false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import {baseTitle} from '../configs/squareConfig'
import {addUser, getUsers, deleteUsers, editUsers, getLoginHistory, editMyPassword} from '../http/api'

export default {
  name: "Users",
  data() {
    return {
      token: this.$cookies.get('token'),
      userLists: [],
      addLayer: false,
      editLayer: false,
      historyLayer: false,
      addForm: {},
      editForm: {},
      loginHistory: [],
      editPwdLayer: false,
      editPwdForm: {},
      权限配置: [
        ['修改自己的密码'],
        ['来源厂家修改', '来源厂家删除'],
        [
          '方片库存查看',
          '方片透视表查看',
          '方片导入',
          '方片导出',
          '方片覆盖导入',
          '方片透视数据导出',
          '方片批量修改',
          '方片日志查看',
          '方片单个修改',
          '删除方片库存'
        ],
        [
          '外延片库存查看',
          '外延片导入',
          '外延片覆盖导入',
          '外延片批量修改',
          '外延片删除',
          '外延片数据导出',
          '外延片单行修改'
        ],
        [
          '非在制外延片批量修改',
          '非在制外延片覆盖导入',
          '非在制外延片删除',
          '非在制外延片单行修改'
        ],
        [
          '查看外延入库清单',
          '查看外延出库清单',
          '导出外延入库清单',
          '导出外延出库清单',
        ],
        [
          '原料基础信息查看', '原料基础信息导入',
          '原料基础信息导出', '原料基础信息添加',
          '原料基础信息修改', '原料库存查看',
          '原料库存导入', '原料库存导出',
          '原料入库', '原料出库',
          '原料退库', '原料入库日志查看',
          '原料出库日志查看', '原料入库日志导出',
          '原料出库日志导出', '原料出入库日志修改',
          '原料采购单价查看'
        ],
        ['新建用户', '用户列表查看', '用户密码及权限修改', '删除用户', '查看用户登录历史'],
        ['客户资料查看', '客户资料导入', '客户资料导出'],
        ['方片工具-CSV导入', '方片工具-表格导入', '方片工具-扫码出入库'],
        [
          '圆片库存查看',
          '圆片库存导入',
          '圆片覆盖导入',
          '圆片导入片号修改',
          '圆片选中修改',
          '圆片选中删除',
          '圆片导出',
          '圆片单行修改'
        ],
      ],
    }
  },
  methods: {
    getUsers_() {
      const token = this.token
      getUsers({token})
        .then(res => {
          if (res.data.code === 'OK') {
            let rs = res.data.rs
            rs.forEach((item, index) => {
              rs[index].启用状态 = item.启用状态 === '启用'
              rs[index].权限 = JSON.parse(item.权限)
            })
            this.userLists = rs
          }
        })
    },
    showAddLayer() {
      this.addLayer = true
    },
    showEditLayer(index, row) {
      this.editForm = JSON.parse(JSON.stringify(row))
      this.editLayer = true
    },
    showDelLayer(index, row) {
      this.$confirm(`确定删除用户【${row.用户名}】吗？`)
        .then(res => {
          const token = this.token
          const id = row.id
          deleteUsers({token, id})
            .then(res => {
              if (res.data.code === 'OK') {
                this.$notify({
                  type: 'success',
                  message: '删除成功'
                })
                this.getUsers_()
              }
            })
            .catch(err => console.log(err))
        })
        .catch(err => console.log(err))
    },
    doAddUser() {
      const addForm = this.addForm
      const token = this.token
      addUser({token, addForm})
        .then(res => {
          if (res.data.code === 'OK') {
            this.$message({
              type: "success",
              message: '账户创建成功'
            })
          }
          this.addForm = {}
          this.addLayer = false
          this.getUsers_()
        })
    },
    doEditUser() {
      const token = this.token
      const editForm = this.editForm
      editUsers({token, editForm})
        .then(res => {
          if (res.data.code === 'OK') {
            this.$notify({
              type: 'success',
              message: '修改成功'
            })
            this.editLayer = false
            this.editForm = {}
            this.getUsers_()
          }
        })
        .catch(err => console.log(err))
    },
    doEditPwd() {
      const editPwdForm = this.editPwdForm
      const 新密码 = editPwdForm.新密码
      const 确认新密码 = editPwdForm.确认新密码
      let message = ''
      if (!新密码 || !确认新密码) message = '请输入完整再提交'
      if (!message && 新密码.length < 6) message = '密码长度建议在6位以上'
      if (!message && 新密码 !== 确认新密码) message = '两次输入的密码不一致'
      if (message) {
        this.$message({
          type: 'error',
          message
        })
        return
      }
      const token = this.token
      editMyPassword({token, editPwdForm})
        .then(res => {
          if (res.data.code === 'OK') {
            this.$message({
              type: 'success',
              message: '修改成功，下次登录请使用新密码'
            })
            this.editPwdLayer = false
            this.editPwdForm = {}
          }
        })
        .catch(err => console.log(err))
    },
    showLogLayer(index, row) {
      const id = row.id
      const token = this.token
      getLoginHistory({token, id})
        .then(res => {
          if (res.data.code === 'OK') {
            this.historyLayer = true
            let rs = res.data.rs
            rs.forEach((item, index) => {
              rs[index].登录时间 = moment(item.登录时间).format('YYYY/MM/DD HH:mm:ss')
            })
            this.loginHistory = rs
          }
        })
        .catch(err => console.log(err))
    },
    statusFormat(row) {
      return row.启用状态 ? '启用' : '禁用'
    },
    canFormat(row) {
      return row.权限.join('、')
    },
  },
  activated() {
    document.title = '用户管理 - ' + baseTitle
    this.getUsers_()
  },
}
</script>

<style scoped>

</style>
